import styles from './JobDescription.module.scss';
import { useWindowSizeKind } from '../hooks/useWindowSizeKind';

type JobProps = {
  jobName: string;
  link: string;
  bgImgStyle: string;
};

const Job = ({ jobName, link, bgImgStyle }: JobProps) => {
  return (
    <a href={link} className={styles.link} target="_blank" rel="noreferrer">
      <div className={`${styles.bg} ${bgImgStyle}`}>
        <div className={styles.inner}>
          <div dangerouslySetInnerHTML={{__html: jobName}}></div>
        </div>
      </div>
    </a>
  );
}

const JobDescription = () => {
  const [ windowSizeKind ] = useWindowSizeKind();

  return (
    <div>
      <h2 className={styles.title}>iimonでは{windowSizeKind === 'SP' && (<br/>)}エンジニアを募集しています！<br/>私たちと一緒に働きませんか？</h2>
      <ul className={styles.jobList}>
        <li>
          <Job jobName="フロントエンドエンジニア<br/>(リーダー)" link="https://bead-art-1d1.notion.site/7b9da62120414c1e95d691f207d3ab86?pvs=4" bgImgStyle={styles.bgImg02} />
        </li>
        <li>
          <Job jobName="フロントエンドエンジニア" link="https://bead-art-1d1.notion.site/378072f6ed7c416489c20153e3e4a433?pvs=4" bgImgStyle={styles.bgImg02} />
        </li>
        <li>
          <Job jobName="エンジニアリング<br/>マネージャー" link="https://bead-art-1d1.notion.site/cd4c58290a6741caaf11dfa51612efc6?pvs=4" bgImgStyle={styles.bgImg01} />
        </li>
        <li>
          <Job jobName="サーバーサイドエンジニア" link="https://bead-art-1d1.notion.site/e21b25838c70408aa972d242b7a30b9c" bgImgStyle={styles.bgImg00} />
        </li>
      </ul>
    </div>
  );
};

export default JobDescription;
