import styles from './Footer.module.css'
import { useEffect } from 'react';
import { HEADLINES } from '../constants/global'
import { useWindowSizeKind } from '../hooks/useWindowSizeKind'

const Footer = () => {
  const [ windowSizeKind ] = useWindowSizeKind();

  // 別サイトからアンカーリンク付きで遷移してきた時
  // レンダリングが完了せず移動できないので、移動できるようにする
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerInner}>
        <div>
          <a href="https://iimon.co.jp/" target="_blank" rel="noreferrer">
            <img src="/img/pc/iimon_logo.png" alt="iimon" className={styles.iimonLogo}/>
          </a>
          {windowSizeKind === 'SP' && (
            <div className={styles.address}>
              <div className={styles.companyName}>株式会社iimon</div>
              〒103-0015<br/>東京都中央区日本橋箱崎町1-2　THE SHORE日本橋茅場町5階
            </div>
          )}
        </div>
        <ol className={styles.items}>
          {HEADLINES.map((h, idx) => {
            return (
              <li key={idx} className={styles.item}><a href={h.href}>{h.name}</a></li>
            )
          })}
        </ol>
        {windowSizeKind === 'PC' && (
          <div className={styles.address}>
            <div className={styles.companyName}>株式会社iimon</div>
            〒103-0015<br/>東京都中央区日本橋箱崎町1-2<br/>THE SHORE日本橋茅場町5階
          </div>
        )}
      </div>
      <div className={styles.caption}>©2023 iimon.inc</div>
    </footer>
  )
}

export default Footer;
